.api-key-table {
  .ant-table {
    .ant-table-tbody {
      max-height: calc(100vh - 273px);
    }
  }
  .ant-table-container table .ant-table-cell:nth-child(3) {
    width: 20%;
  }
  .ant-table-container table .ant-table-cell:nth-child(4),
  .ant-table-container table .ant-table-cell:nth-child(2),
  .ant-table-container table .ant-table-cell:last-child {
    width: 18%;
  }
  .ant-table-container table .ant-table-cell:last-child {
    text-align: center;
  }
  .link-color{
    font-size: 16px;
  }
}
