@use "../../assets/sass/variables";

.logs-table {
  .ant-table {
    .ant-table-tbody {
      max-height: calc(100vh - 277px);
    }
  }
  .ant-table-container table .ant-table-cell:nth-child(2) {
    width: 28%;
  }
  .ant-table-container table .ant-table-cell:last-child {
    width: 18%;
  }
}

.date-range {
  &.ant-picker {
    border-radius: 3px;
    height: 40px;
    background: transparent;
    border-color: #38383d;
    color: variables.$baseTextColor;
    border: 1px solid variables.$borderColor;
    box-shadow: none !important;
    width: 100%;
    font-size: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    .ant-picker {
      &-input {
        & > input {
          font-size: 12px;
        }
      }
    }
    &-focused,
    &:hover {
      border-color: variables.$linkColor;
    }
    &-range {
      .ant-picker-active-bar {
        background: variables.$linkColor;
      }
    }
  }
}
.ant-picker-panel-container {
  font-size: 12px;
  .ant-picker {
    &-cell-in-view {
      &.ant-picker-cell-today {
        .ant-picker-cell-inner:before {
          border-color: variables.$linkColor;
        }
      }
      &.ant-picker-cell-range-start,
      &.ant-picker-cell-range-end,
      &.ant-picker-cell-range-selected {
        .ant-picker-cell-inner {
          background: variables.$linkColor;
        }
      }
      &.ant-picker-cell-in-range:before {
        background: rgba(variables.$linkColor, 0.1);
      }
      // &.ant-picker-cell-range-end {
      //   &:not(.ant-picker-cell-range-end-single):before {
      //     background: rgba(variables.$linkColor, 0.1);
      //   }
      // }
    }
    &-time-panel-column {
      > li.ant-picker-time-panel-cell-selected {
        .ant-picker-time-panel-cell-inner {
          background: rgba(variables.$linkColor, 0.1);
        }
      }
    }
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
      .ant-picker-cell-range-start-single
    ):before {
    background: rgba(variables.$linkColor, 0.1);
  }
  .ant-btn-primary {
    background: variables.$btnGradient;
    border-color: variables.$CardBorderColor;
    font-size: 12px;
    border-radius: 3px;
    padding: 4px 14px;
    height: 30px;
    &:hover {
      background: transparent;
    }
  }
}
