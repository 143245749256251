@use "../../../assets/sass/variables";
@import "../../../assets/sass/checkbox";

.legends {
  padding-top: 6px;
  margin-right: 1.5rem;
  // margin-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  .ant-checkbox {
    font-feature-settings: "tnum";
    box-sizing: border-box;
    color: rgba(0,0,0,.85);
    cursor: pointer;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    line-height: 1;
    list-style: none;
    margin: 0;
    outline: none;
    padding: 0;
    position: relative;
    white-space: nowrap;
    bottom: 5px;
  }
  .ant-checkbox + span {
    padding-left: 0px;
  }

  .ant-checkbox-group {
    padding-left: 8px;
    &-item {
      margin-right: 0px;
    }
  }

  &-item {
    border-radius: 3px;
    padding: 3px 8px 3px 30px;
    // margin-left: -22px;
    // margin-right: 20px;
    margin: 0 20px 10px -22px;
  }
}
