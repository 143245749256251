.service-table {
  word-break: auto-phrase;
  &-status {
    width: 10% !important;
  }
  &-statusNew {
    width: 10% !important;
  }

  &-name {
    width: 13% !important;
  }
  &-nameNew {
    width: 15% !important;
  }

  &-description {
    width: 35% !important;
  }
  &-descriptionNew {
    width: 60% !important;
  }

  &-lastFailedAt {
    width: 13% !important;
  }

  &-lastResults {
    text-align: center !important;
    width: 15% !important;
  }

  &-availability {
    width: 10% !important;
    text-align: center;

    span {
      display: flex;
      gap: 4px;
    }
  }

  &-availabilityNew {
    width: 15% !important;
    text-align: center;

    span {
      display: flex;
      gap: 4px;
    }
  }

  .ant-table-tbody {
    max-height: calc(100vh - 245px) !important;
  }
}