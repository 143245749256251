.browse-file{
    width:100%;
    &>span {
        width: 100%;
        display: flex;
        gap:20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}