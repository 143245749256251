@import "../../../assets/sass/variables";
.theme-config {
  &-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
  }
  &-card {
    box-shadow: 0px 2px 15px 0px $boxShadowColor;
    border: 1px solid $CardBorderColor;
    border-radius: 3px;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
    h4 {
      padding: 14px 20px;
      background: $tabBgColor;
      border-bottom: 1px solid $CardBorderColor;
    }
    h5 {
      margin-bottom: 16px;
    }
    p {
      font-family: "Font-Medium", Arial, Sans-Serif;
    }
    a {
      display: block;
    }
    &-container {
      padding: 14px 20px 30px 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 50px;
      row-gap: 22px;
      &-item {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        &:not(:last-child) {
          margin-bottom: 15px;
        }
        .color-box {
          width: 20px;
          height: 20px;
          border: 1px solid $CardBorderColor;
          border-radius: 2px;
          box-shadow: 0px 2px 15px 0px $boxShadowColor;
        }
        .sketch-picker {
          top: 20px;
          svg {
            width: 16px;
            height: 16px;
            margin-bottom: 4px;
            color: $linkColor;
          }
        }
      }
      .card-divider {
        width: 100%;
        height: 1px;
        background: $CardBorderColor;
        grid-column: 1/3;
      }
      &.branding-logo {
        h5 {
          margin-bottom: 6px;
        }
        .sub-heading {
          margin-bottom: 16px;
        }
      }
    }
    .heading-text-color {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding: 14px 20px 26px 20px;
      .theme-config-card-container-item:nth-child(3) {
        margin-bottom: 0;
      }
    }
    &-img {
      padding: 18px 20px;
      background: $tabBtnFillColor;
      border-radius: 3px;
      margin-bottom: 4px;
    }
  }
}
