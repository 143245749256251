@use "../../../assets/sass/variables";

.disable-link {
  pointer-events: none;
}

.ant-layout-sider-trigger {
  margin-bottom: 10px !important;
  width: auto !important;
  background: transparent !important;
  svg {
    width: 17px;
    height: 17px;
  }
}

.sidebar {
  .logo {
    margin-bottom: 40px;
  }
  &.ant-layout-sider {
    padding: 25px;
    background: variables.$bgGradientX;
    height: 100%;
  }

  &-nav {
    &-link {
      margin: 30px 0;
      position: relative;
      color: #bdbdbe;

      &.active,
      &:hover {
        color: variables.$baseColor;
      }

      svg {
        width: 17px;
        height: 17px;
      }
    }
  }
}

.rotate-icon {
  transform: rotate(180deg);
}
