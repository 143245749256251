@import "./variables";

.legends {
  .ant-checkbox-wrapper {
    color: $baseColor;
    font-size: 12px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $baseColor;
    border-color: $baseColor;
  }
  .ant-checkbox-checked:after {
    border: 0px;
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: $baseColor;
    background-color: $baseColor;
  }
  .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: #434448;
  }
}
.search-checkbox {
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: $linkColor;
    background-color: $linkColor;
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-checked:after {
    border-color: $linkColor;
  }
}
.ant-checkbox-checked .ant-checkbox-inner{
  background-color: $darkBlue;
    border-color: $darkBlue;
}
.ant-checkbox-input:focus+.ant-checkbox-inner, .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-checked:after{
  border-color: $darkBlue;
}