@import "../../../assets/sass/variables";
.service-card {
  &-group {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
    gap: 22px;
  }
  &.ant-card {
    // padding: 35px 50px;
    padding: 15px 15px 15px 40px;
    border-bottom: 10px solid;
  }
  p {
    font-family: "Font-Medium", Arial, Sans-Serif;
  }
  &-icon {
    margin-bottom: 4px;
    svg {
      width: 30px;
      height: 30px;
    }
  }
  &.disabled-item {
    // pointer-events: none;
    opacity: 0.5;
    user-select: none;
  }
}

.service {
  &-status {
    &-title {
      font-family: "Font-Semibold", Arial, Sans-Serif;
      font-size: 22px;
      color: #5F3CAB;
      // margin-bottom: 12px;
      // &:not(:first-child) {
      //   margin-top: 40px;
      // }
    }
    &-number {
      background-color: #5F3CAB;
      color: white;
      padding: 2px 4px;
      border-radius: 3px;
    }
    &:not(:first-child) {
      margin-top: 40px;
    }
    &-text {
      font-family: "Font-medium", Arial, Sans-Serif;
      font-size: 14px;
      margin-top: 6px;
    }
  }
}
