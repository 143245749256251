@import "../../assets/sass/variables";
.ml-config-card {
  width: 460px;
  padding: 50px 35px 30px 50px;
  background: $btnGradient;
  color: $baseColor;
  border-radius: 10px;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.1);
  h2 {
    font-size: 20px;
    color: $baseColor;
  }
  span {
    display: block;
  }
  &-group {
    column-gap: 80px;
    margin-top: 40px;
  }
}
