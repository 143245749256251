%primary-btn {
  height: 36px;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
  font-size: 12px;

  &:focus {
    outline: none;
  }
}

%tag-btn {
  border-radius: 4px;
  line-height: 1;
  height: 40px;
  text-align: left;
  font-size: 12px;
}

@mixin tab-btn($bg_color) {
  @if $bg_color == tab-active {
    .tab-active {
      background: $tabBtnFillColor;
      color: $linkColor;
      border: 0px;
      font-family: Font-Medium;
      @extend %tag-btn;

      &:hover,
      &:active,
      &:focus {
        background: $tabBtnFillColor;
        color: $linkColor;
        border: 0px;
      }
    }
  }
  @if $bg_color == tab-inactive {
    .tab-inactive {
      background: $tabBtnColor;
      color: $baseTextColor;
      border: 0px;
      @extend %tag-btn;

      &:hover {
        background: $tabBtnFillColor;
        color: $linkColor;
        border: 0px;
      }
    }
  }
}

@mixin btn($bg_color) {
  @if $bg_color == fill {
    .fill {
      background: $btnGradient;
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
      color: $baseColor;
      font-family: Font-Semibold;
      @extend %primary-btn;

      &:hover, &:focus {
        background: $btnGradient;
        color: $baseColor;
      }
    }
  }

  @if $bg_color == outline {
    .outline {
      border: 2px solid $linkColor;
      background: $baseColor;
      color: $linkColor;
      font-family: Font-Semibold;
      @extend %primary-btn;

      &:hover {
        background: $linkColor;
        color: $baseColor;
        border-color: $linkColor;
      }
    }
  }
}

.ant-btn:focus,
.ant-btn:hover {
  color: $linkColor;
  border-color: $linkColor;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:active,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:hover {
  background: $baseColor;
  color: $baseTextColor;
  border-color: $baseTextColor;
  opacity: 0.5;
}
.ant-btn[disabled],
.ant-btn[disabled]:active,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:hover {
  background: $baseColor;
  color: $baseTextColor;
  border-color: $baseTextColor;
  opacity: 0.5;
}

//Shadow-btn
.shadow-btn {
  border: 1px solid #f5f9ff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
}
