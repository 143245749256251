.container {
    display: flex;
    flex-direction: column;
    gap: 50px;
}
.install {
    display: flex;
    gap: 20px;
}

.input-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.input-description {
    display: flex;
    gap: 20px;
}

.input-key {
    display: flex;
    gap: 72px;
}

.activate-button {
    display: flex;
    justify-content: flex-end
}

.heading {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.activate-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
}