@import "../../assets/sass/variables";
.deployment-table {
  .ant-table {
    .ant-table-tbody {
      max-height: calc(100vh - 226px);
    }
  }
  .ant-table-container table .ant-table-cell:nth-child(2),
  .ant-table-container table .ant-table-cell:nth-child(4) {
    width: 18%;
  }
  .ant-table-container table .ant-table-cell:last-child {
    width: 12%;
    text-align: center;
  }
  .link-color{
    font-size: 16px;
  }
}
