.hold-modal {
  width: 470px;
  position: absolute;
  top: 253px;
  padding: 25px 20px;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;

  .modal-content {
    width: 430px;
    display: flex;
    flex-direction: column;
    gap: 25px;

    .date-picker-content {
      display: flex;
      flex-direction: column;
      width: 430px;
      gap: 10px;

      .date-picker-typography {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 14.52px;
        text-align: left;
      }

      .date-picker-box {
        display: flex;
        width: 425px;
        gap: 25px;

        .date-picker {
          width: 200px;
        }
      }
    }

    .page-range-content {
      width: 430px;
      display: flex;
      flex-direction: column;
      gap: 15px;

      .page-range-typography {
        font-family: "Inter", sans-serif;
        font-size: 12px;
        font-weight: 600;
        line-height: 14.52px;
        text-align: left;

        .typography-one {
          height: 15px;
          font-weight: 600;
        }
        .typography-two {
          height: 15px;
          font-weight: 400;
        }
      }

      .page-range-input {
        display: flex;
        gap: 8px;

        .page-range-input-one,
        .page-range-input-two {
          width: 197px;
          height: 36px;
        }
      }
    }

    .btn-files {
      width: 450px;
      height: 35px;
      display: flex;
      justify-content: space-between;

      .file-text {
        width: 125px;
        height: 17px;
        gap: 10px;

        .file-icon {
          width: 16px;
          height: 16.41px;
          border: 0.5px solid #5f3cab;
        }

        .file-typography {
          width: 99px;
          height: 17px;
          font-family: "Inter", sans-serif;
          font-size: 14px;
          font-weight: 600;
          line-height: 16.94px;
          text-align: left;
          color: #5f3cab;
        }
      }

      .btn {
        border-radius: 3px;
        background: #3232a6;
        color: white;
      }
    }
    .btn-get-files {
      width: 142px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 15px 10px 15px;
      gap: 10px;
      border-radius: 3px;
      background: #3232a6;
      color: white;
      margin-left: 300px;
    }
  }
}
