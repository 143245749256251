@use "../../assets/sass/variables";
@import "../../assets/sass/mixins";

.application {
  &-heading {
    @include font_size_color(16px, variables.$blackColor, null, null, Font-Semibold);
  }

  &-card {
    &.ant-card {
      padding: 24px 30px;
      border-radius: 10px;
      border: 1px solid variables.$borderColor;

      .ant-card-body {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }

    &-grid {
      display: grid;
      gap: 30px
    }

    &-title {
      @include font_size_color(16px, variables.$linkColor, null, null, Font-Semibold);
      margin-bottom: 4px;
    }

    &-title-hovered {
      @include font_size_color(16px, white, null, null, Font-Semibold);
      margin-bottom: 4px;
    }

    &-description-hovered {
      color: white;
    }

    &-subheading {
      font-size: 23px;
    }
  }
}

.task-card {
  &-heading {
    font-family: "Font-Semibold", Arial, Sans-Serif;
    font-size: 16px;
    color: variables.$blackColor;
  }

  &-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
  }

  &-title {
    font-family: "Font-Semibold", Arial, Sans-Serif;
    font-size: 14px;
    color: variables.$linkColor;
  }
}

.hover {
  path {
    fill: white;
  }
}