@use "../sass/variables";

.tabs {
  .ant-tabs-tab {
    padding: 10px 20px 20px;
    color: variables.$tabLinkHover;
    font-size: 12px;
  }
  // .ant-tabs-tab:hover {
  //   color: variables.$baseTextColor;
  // }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 5px;
  }

  .ant-tabs-tab-btn:focus {
    color: variables.$textPrimaryColor;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: variables.$linkColor;
    font-family: "font-Semibold";
    // font-size: 1.125rem;
  }
  .ant-tabs-ink-bar {
    background: variables.$linkColor;
  }

  .ant-tabs-nav-list {
    width: 100%;
    gap: 10px;
    // justify-content: space-between;
    // background-color: variables.$bgColor;
  }
  .ant-tabs-top > .ant-tabs-nav:before {
    border-bottom:1px solid rgb(50 50 166 / 20%);
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
    border-radius: 25px;
    height: 3px;
  }

  .ant-tabs-left {
    &.ant-tabs > .ant-tabs-nav {
      width: 100%;
    }
    .ant-tabs-nav .ant-tabs-tab {
      padding-left: 0;
    }
    .ant-tabs-tab {
      padding-left: 0;
      border-bottom: 1px solid #d9d9d9;
    }
    .ant-tabs-tab.ant-tabs-tab-active {
      border-bottom: 3px solid variables.$linkColor;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: variables.$linkColor;
      font-family: Font-Semibold;
    }
    .ant-tabs-tab-btn:active,
    .ant-tabs-tab-btn:focus,
    .ant-tabs-tab-remove:active,
    .ant-tabs-tab-remove:focus {
      color: variables.$linkColor;
    }
    .ant-tabs-tab {
      color: variables.$baseTextColor;
      font-size: 12px;
    }
    .ant-tabs-ink-bar {
      background: transparent;
    }
    .ant-tabs-content-holder,
    div > .ant-tabs-content-holder {
      border: none;
    }
    > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
      margin-top: 0px;
    }
  }
}

.tabs-content {
  border-radius: 3px 0px 0px 6px;
  border: 1px solid #F5F9FF;
  background: #FFF;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
  padding: 30px 35px;
}
