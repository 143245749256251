@import "../../assets/sass/variables";
.document {
  &-container-table {
    .ant-table {
      .ant-table-tbody {
        max-height: calc(100vh - 226px);
      }
    }
    .ant-table-container table .ant-table-cell:last-child {
      width: 15%;
      text-align: center;
    }
  }
  &-modal-table {
    .ant-table-container table .ant-table-cell:first-child {
      width: 13%;
      text-align: center;
    }
    .ant-table-container table .ant-table-cell:nth-child(4) {
      width: 25%;
    }
    .ant-table-container table .ant-table-cell:last-child {
      width: 20%;
      text-align: center;
    }
  }
}
.status {
  border: 1px solid $tabBgColor;
  border-radius: 3px;
  box-shadow: 0 2px 15px $boxShadowColor;
  display: inline-block;
  font-family: Font-Medium;
  font-size: 12px;
  padding: 6px 10px;
}
