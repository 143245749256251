.billing-dashboard-container {
  &-row,
  &-inner-row {
    display: grid;
    gap: 40px;
  }
  &-row {
    grid-template-columns: 1fr 33.33%;
  }
  &-inner-row {
    grid-template-columns: repeat(2, 1fr);
  }

  canvas {
    width: 100% !important;
  }
  .ant-card {
    padding: 25px;
    border-radius: 8px;

    p {
      margin-top: 6px;
      margin-bottom: 16px;
    }
    .total-count {
      font-size: 18px;
      font-family: font-bold;
      color: #000;
    }
  }
  .billing-dashboard-container-row:nth-child(2) {
    padding-top: 40px;
  }
  .tabs .ant-tabs-tab {
    padding: 10px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
  .ant-tabs-tab .anticon {
    margin-right: 0;
  }
}
