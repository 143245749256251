@import "../../../assets/sass/variables";
.permission-table {
  .ant-table-container table .ant-table-cell {
    text-align: center;
  }
  .ant-table-container table .ant-table-cell:first-child {
    width: 40%;
    text-align: left;
  }
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: $white;
    top: 49%;
  }
}
