.expandedContent {
  &-title {
    display: flex;
  }

  &-logsTable {
    &-Process {
      width: 50% !important;
    }

    &-time {
      width: 25% !important;
      text-align: left !important;
    }
  }

  &-pageStatusTable {
    &-pageNo {
      width: 5% !important;
      text-align: center !important;
      padding: 10px !important;
    }

    &-time {
      width: 16% !important;
      padding: 10px !important;
    }

    &-status {
      width: 27% !important;
      text-align: left !important;
      padding: 10px !important;
    }

    &-message {
      &-content {
        border-bottom: 1px solid #ddd;
        height: 40px;
        padding: 10px;
      }

      &-process {
        width: 25% !important;
        padding: 0px !important;
      }

      &-startTime {
        width: 17% !important;
        padding: 0px !important;
      }

      &-endTime {
        width: 17% !important;
        text-align: left !important;
        padding: 0px !important;
      }
    }
  }
}

.ant-table-wrapper .ant-table-tbody>tr>td>.ant-table-wrapper:only-child .ant-table {
  margin-block: 0px !important;
  margin-inline: 0px !important;
}