/* keywords.scss */

.page-container {
  .ant-collapse-item {
    width: 100%;
  }

  .fields-container,
  .variations-container,
  .third-division-container {
    width: 100%;
    padding: 10px;

    ul {
      max-height: calc(51vh);
      overflow: auto;
    }

    h2 {
      margin-bottom: 20px;
    }
  }

  .field-button {
    cursor: pointer;
    padding: 8px;
    margin: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #f0f0f0;
    }

    &.selected {
      background-color: #007bff;
      color: #fff;
    }
  }

  .keyword-variation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    margin: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    transition: background-color 0.3s ease;

    .actions {
      display: none;
      gap: 8px;
      font-size: 14px;

      .edit-icon,
      .delete-icon {
        cursor: pointer;
      }
    }

    &:hover {
      background-color: #f0f0f0;

      .actions {
        display: flex;
      }
    }
  }
}